export default {
  name: "ticket",
  component: () => import("../layouts/TicketLayout.vue"),
  children: [
    {
      path: ":hash",
      name: "ticket-detail",
      component: () =>
        import(
          /* webpackChunkName: "Requests List" */ "@/modules/ticket/views/TicketDetail.vue"
        ),
    },
  ],
};
