import { ActionContext } from "vuex";
import { isAxiosError } from "axios";
import api from "../api";
import { InvoiceRequestInterface } from "../types";

export const createRequestAsync = async (
  context: ActionContext<InvoiceRequestInterface, any>
) => {
  const { state } = context;
  const { campaigns, invoices } = state;
  const crab = localStorage.getItem("crab")?.split(" ");

  if (crab) {
    try {
      const { data } = await api.post(
        "/request",
        {
          campaigns,
          invoices: invoices.map((invoice) => ({
            purchaseDate: invoice.purchaseDate,
            invoiceImage: invoice.invoiceImage,
            purchaseCommercialPremise: invoice.purchaseCommercialPremise.value,
            purchasePaymentMethod: invoice.purchasePaymentMethod.value,
            purchaseValue: invoice.purchaseValue,
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${crab[0]}`,
          },
        }
      );
      return data;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          return error.request;
        } else {
          return error;
        }
      }
      return error;
    }
  }
};
