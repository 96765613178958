import { ActionContext } from "vuex";
import { isAxiosError } from "axios";
import { AuthenticationStatusEnum, AuthorizedUserTypeEnum } from "./state";
import { OtpInterface, UserRegisterInterface } from "../types";
import api from "../api";

export interface RootStateInterface {
  auth: {
    status: AuthenticationStatusEnum;
    user: string | null;
    type: AuthorizedUserTypeEnum | null;
    temporaryUser: UserRegisterInterface;
  };
}

export const createUserAsync = async (
  context: ActionContext<RootStateInterface, RootStateInterface>,
  user: UserRegisterInterface
) => {
  const { commit } = context;
  const {
    documentType: { value: documentType },
    documentNumber,
    names,
    surnames,
    genre: { value: genre },
    dateOfBirth,
    email,
    cellPhoneNumber,
    address,
    neighborhood: { value: neighborhood },
    hash,
  } = user;
  try {
    const { data } = await api.post("/register_temporary_customer", {
      documentType,
      documentNumber,
      names,
      surnames,
      genre,
      dateOfBirth,
      email,
      cellPhoneNumber,
      neighborhood,
      address,
      hash,
    });
    commit("updateTemporaryUser", user);
    commit("setTemporaryHash", data);
    return data;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    }
    return error;
  }
};

export const validateRegisterOtpAsync = async (
  context: ActionContext<RootStateInterface, RootStateInterface>,
  otp: OtpInterface
) => {
  const { commit } = context;
  try {
    const { data } = await api.post("/validate_temporary_otp", {
      otp: otp.otp,
      hash: otp.hash,
    });
    commit("setAuth", data);
    commit("resetTemporaryUser");
    return data;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    }
    return error;
  }
};

export const validateLoginOtpAsync = async (
  context: ActionContext<RootStateInterface, RootStateInterface>,
  otp: OtpInterface
) => {
  const { commit } = context;
  try {
    const { data } = await api.post(
      "/login",
      {},
      {
        params: {
          otp: otp.otp,
          hash: otp.hash,
        },
      }
    );
    commit("setAuth", data);
    commit("resetTemporaryUser");
    return data;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    }
    return error;
  }
};

export const validateUserAsync = async (
  context: ActionContext<RootStateInterface, RootStateInterface>,
  documentNumber: string
) => {
  const { commit } = context;
  try {
    const { data } = await api.post(
      "/validate_customer_user",
      {},
      { params: { document_number: documentNumber } }
    );
    commit("setTemporaryHash", data);
    commit("setTemporaryDocumentNumber", documentNumber);
    return data;
  } catch (error: unknown) {
    if (isAxiosError(error)) {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    }
    return error;
  }
};

export const logout = async (
  context: ActionContext<RootStateInterface, RootStateInterface>
) => {
  const { commit } = context;
  commit("resetAuth");
};

export const checkAuthAsync = async (
  context: ActionContext<RootStateInterface, RootStateInterface>
) => {
  const { commit } = context;
  const crab = localStorage.getItem("crab")?.split(" ");
  if (!crab) {
    commit("resetAuth");
    return false;
  }
  try {
    const { data } = await api.post(
      "/verify",
      {},
      {
        headers: {
          Authorization: `Bearer ${crab[0]}`,
        },
      }
    );
    commit("setAuth", {
      access_token: crab[0],
      refresh_token: crab[1],
      type: data,
    });
    return true;
  } catch (verify_error) {
    try {
      const { data } = await api.post(
        "/refresh",
        {},
        {
          headers: {
            Authorization: `Bearer ${crab[1]}`,
          },
        }
      );
      commit("setAuth", data);
      return true;
    } catch (error) {
      commit("resetAuth");
      return false;
    }
  }
};
