export default {
  name: "auth",
  component: () =>
    import(
      /* webpackChunkName: "Auth Layout" */ "@/modules/auth/layouts/AuthLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "auth-navigation",
      component: () =>
        import(
          /* webpackChunkName: "Auth Layout" */ "@/modules/auth/views/AuthNavigation.vue"
        ),
    },
    {
      path: "",
      name: "auth-login",
      component: () =>
        import(
          /* webpackChunkName: "Auth Layout" */ "@/modules/auth/views/AuthLogin.vue"
        ),
    },
    {
      path: "",
      name: "auth-register",
      component: () =>
        import(
          /* webpackChunkName: "Auth Layout" */ "@/modules/auth/views/AuthRegister.vue"
        ),
    },
    {
      path: "",
      name: "auth-otplogin",
      component: () =>
        import(
          /* webpackChunkName: "Auth Layout" */ "@/modules/auth/views/AuthOtp.vue"
        ),
      props: {
        mode: "login",
      },
    },
    {
      path: "",
      name: "auth-otpregister",
      component: () =>
        import(
          /* webpackChunkName: "Auth Layout" */ "@/modules/auth/views/AuthOtp.vue"
        ),
      props: {
        mode: "register",
      },
    },
  ],
};
