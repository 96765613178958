import { RootStateInterface } from "./actions";

export const getTemporaryUser = (state: RootStateInterface["auth"]) => {
  return state.temporaryUser;
};

export const getTemporaryDocumentNumber = (
  state: RootStateInterface["auth"]
) => {
  return state.temporaryUser.documentNumber;
};

export const getHash = (state: RootStateInterface["auth"]) => {
  return state.temporaryUser.hash ? state.temporaryUser.hash : "";
};

export const getStatus = (state: RootStateInterface["auth"]) => {
  return state.status;
};
