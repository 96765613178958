import { SuccessfulResponseInterface as SuccessfulResponseInterfaceRegister } from "../composables/useRegister";
import { SuccessfulResponseInterface as SuccessfulResponseInterfaceOtp } from "../composables/useOtp";
import { UserRegisterInterface } from "../types";
import { RootStateInterface } from "./actions";
import { AuthenticationStatusEnum, AuthorizedUserTypeEnum } from "./state";

export const setAuth = (
  state: RootStateInterface["auth"],
  payload: SuccessfulResponseInterfaceOtp
) => {
  localStorage.setItem(
    "crab",
    `${payload.access_token} ${payload.refresh_token}`
  );
  state.status = AuthenticationStatusEnum.Authenticated;
  if (payload.type === AuthorizedUserTypeEnum.Customer)
    state.type = payload.type;
  if (payload.type === AuthorizedUserTypeEnum.Operator)
    state.type = payload.type;
};

export const resetAuth = (state: RootStateInterface["auth"]) => {
  state.status = AuthenticationStatusEnum.Unauthenticated;
  state.type = null;
  localStorage.removeItem("crab");
};

export const setTemporaryHash = (
  state: RootStateInterface["auth"],
  payload: SuccessfulResponseInterfaceRegister
) => {
  state.temporaryUser.hash = payload.hash;
};

export const setTemporaryDocumentNumber = (
  state: RootStateInterface["auth"],
  payload: string
) => {
  state.temporaryUser.documentNumber = payload;
};

export const resetTemporaryUser = (state: RootStateInterface["auth"]) => {
  state.temporaryUser = {
    documentType: {
      value: "",
      label: "",
    },
    documentNumber: "",
    names: "",
    surnames: "",
    genre: {
      value: "",
      label: "",
    },
    dateOfBirth: "",
    email: "",
    cellPhoneNumber: "",
    address: "",
    neighborhood: {
      value: "",
      label: "",
    },
    hash: undefined,
  };
};

export const updateTemporaryUser = (
  state: RootStateInterface["auth"],
  payload: UserRegisterInterface
) => {
  state.temporaryUser = payload;
};
