import axios from "axios";
import { API_ROUTE } from "@/config";

export interface ErrorResponseInterface {
  detail: {
    field: string;
    msg: string;
  }[];
}

export interface PagedDataInterface {
  total: number;
  num_pages: number;
  current_page: number;
  per_page: number;
  results: {
    id: string;
    name: string;
  }[];
}

export const isErrorResponse = (
  resp: unknown
): resp is ErrorResponseInterface => {
  if (typeof resp !== "object" || resp === null) {
    return false;
  }
  if (!Object.hasOwnProperty.call(resp, "detail")) {
    return false;
  }
  const errorResponse = resp as ErrorResponseInterface;
  if (
    typeof errorResponse.detail !== "object" ||
    errorResponse.detail === null
  ) {
    return false;
  }
  if (!Array.isArray(errorResponse.detail)) {
    return false;
  }
  for (const item of errorResponse.detail) {
    if (
      typeof item !== "object" ||
      typeof item.field !== "string" ||
      typeof item.msg !== "string"
    ) {
      return false;
    }
  }
  return true;
};

export default axios.create({
  baseURL: API_ROUTE,
});
