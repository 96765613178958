export default {
  name: "invoice",
  component: () =>
    import(
      /* webpackChunkName: "Invoice Layout" */ "@/modules/invoice/layouts/InvoiceLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "invoice-campaign",
      component: () =>
        import(
          /* webpackChunkName: "Invoice Campaign" */ "@/modules/invoice/views/InvoiceCampaign.vue"
        ),
    },
    {
      path: "",
      name: "invoice-register",
      component: () =>
        import(
          /* webpackChunkName: "Invoice Register" */ "@/modules/invoice/views/InvoiceRegister.vue"
        ),
    },
    {
      path: "",
      name: "invoice-list",
      component: () =>
        import(
          /* webpackChunkName: "Invoice List" */ "@/modules/invoice/views/InvoiceList.vue"
        ),
    },
  ],
};
