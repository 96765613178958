import { InvoiceRequestInterface } from "../types";

export const getExcludes = (state: InvoiceRequestInterface) => {
  const indices = new Set<number>();
  state.excludes.forEach((arr: number[]) => {
    arr.forEach((index: number) => {
      indices.add(index);
    });
  });
  return Array.from(indices);
};

export const getCampaigns = (state: InvoiceRequestInterface) => {
  return state.campaigns.join(",");
};

export const getInvoicesCount = (state: InvoiceRequestInterface) => {
  return state.invoices.length;
};

export const getInvoices = (state: InvoiceRequestInterface) => {
  return state.invoices.map((invoice) => ({
    commercialPremise: invoice.purchaseCommercialPremise.label,
    amount: invoice.purchaseValue,
    date: invoice.purchaseDate,
    info: invoice.invoiceInfo,
  }));
};

export const getInvoiceExist = (state: InvoiceRequestInterface) => {
  return (payload: string): boolean => {
    const foundInvoice = state.invoices.find(
      (invoice) => invoice.invoiceImage === payload
    );
    return !!foundInvoice;
  };
};
