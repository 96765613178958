import { createStore } from "vuex";
import auth from "@/modules/auth/store";
import invoice from "@/modules/invoice/store";

export default createStore({
  modules: {
    auth,
    invoice,
  },
});
