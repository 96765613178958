import { UserRegisterInterface } from "../types";

export enum AuthenticationStatusEnum {
  Authenticated = "AUTHENTICATED",
  Unauthenticated = "UNAUTHENTICATED",
}

export enum AuthorizedUserTypeEnum {
  Customer = "CTM",
  Operator = "PWD",
}

export default () => ({
  status: AuthenticationStatusEnum.Unauthenticated,
  user: null as string | null,
  type: null as AuthorizedUserTypeEnum | null,
  temporaryUser: {
    documentType: {
      value: "",
      label: "",
    },
    documentNumber: "",
    names: "",
    surnames: "",
    genre: {
      value: "",
      label: "",
    },
    dateOfBirth: "",
    email: "",
    cellPhoneNumber: "",
    address: "",
    neighborhood: {
      value: "",
      label: "",
    },
    hash: undefined,
  } as UserRegisterInterface,
});
