import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import store from "@/store";

export const authRequiresGuard = async (
  _: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const auth = await store.dispatch("auth/checkAuthAsync");
  if (auth) next();
  else next({ name: "auth-navigation" });
};

export const noAuthRequiresGuard = async (
  _: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const auth = await store.dispatch("auth/checkAuthAsync");
  if (!auth) next();
  else next({ name: "home-page" });
};
