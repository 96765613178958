export default {
  name: "requests-layout",
  component: () =>
    import(
      /* webpackChunkName: "Requests Layout" */ "@/modules/requests/layouts/RequestsLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "requests-list",
      component: () =>
        import(
          /* webpackChunkName: "Requests List" */ "@/modules/requests/views/RequestsList.vue"
        ),
    },
    {
      path: ":requestId",
      name: "requests-detail",
      component: () =>
        import(
          /* webpackChunkName: "Requests List" */ "@/modules/requests/views/RequestsDetail.vue"
        ),
    },
  ],
};
