import { InvoiceRequestInterface, InvoiceStoreInterface } from "../types";

export const setCampaign = (
  state: InvoiceRequestInterface,
  payload: number
) => {
  if (!state.campaigns.includes(payload)) {
    state.campaigns.push(payload);
  }
};

export const removeCampaign = (
  state: InvoiceRequestInterface,
  payload: number
) => {
  const indexToRemove = state.campaigns.indexOf(payload);
  if (indexToRemove >= 0) {
    state.campaigns.splice(indexToRemove, 1);
  }
};

export const setExcludes = (
  state: InvoiceRequestInterface,
  payload: number[]
) => {
  if (!state.excludes.includes(payload)) {
    state.excludes.push(payload);
  }
};

export const removeExcludes = (
  state: InvoiceRequestInterface,
  payload: number[]
) => {
  const indexToRemove = state.excludes.indexOf(payload);
  if (indexToRemove >= 0) {
    state.excludes.splice(indexToRemove, 1);
  }
};

export const setInvoice = (
  state: InvoiceRequestInterface,
  payload: InvoiceStoreInterface
) => {
  state.invoices.push(payload);
};

export const removeInvoice = (
  state: InvoiceRequestInterface,
  payload: number
) => {
  if (payload >= 0 && payload < state.invoices.length) {
    state.invoices.splice(payload, 1);
  }
};

export const resetStatus = (state: InvoiceRequestInterface) => {
  state.campaigns = [];
  state.excludes = [];
  state.invoices = [];
};

export const resetInvoiceInfo = (state: InvoiceRequestInterface) => {
  state.invoices.forEach((invoice) => {
    invoice.invoiceInfo = "";
  });
};

export const setInvoiceInfo = (
  state: InvoiceRequestInterface,
  payload: {
    field: string;
    msg: string;
  }[]
) => {
  payload.forEach((error) => {
    if (!isNaN(Number(error.field))) {
      const index = parseInt(error.field);
      if (state.invoices[index] !== undefined)
        state.invoices[index].invoiceInfo = error.msg;
    }
  });
};
