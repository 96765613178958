import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import authRouter from "@/modules/auth/router";
import homeRouter from "@/modules/home/router";
import invoiceRouter from "@/modules/invoice/router";
import requestsRouter from "@/modules/requests/router";
import ticketRouter from "@/modules/ticket/router";
import {
  noAuthRequiresGuard,
  authRequiresGuard,
} from "@/modules/auth/router/authGuard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/auth",
  },
  {
    path: "/auth",
    ...authRouter,
    beforeEnter: [noAuthRequiresGuard],
  },
  {
    path: "/home",
    ...homeRouter,
    beforeEnter: [authRequiresGuard],
  },
  {
    path: "/invoice",
    ...invoiceRouter,
    beforeEnter: [authRequiresGuard],
  },
  {
    path: "/request",
    ...requestsRouter,
    beforeEnter: [authRequiresGuard],
  },
  {
    path: "/ticket",
    ...ticketRouter,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
